import * as events from '../base/constant';
import { RenderType } from '../base/enum';
import { parentsUntil } from '../base/util';
import { EventHandler } from '@syncfusion/ej2-base';
import { FreezeRender, FreezeContentRender } from '../renderer/freeze-renderer';
/**
 * `Freeze` module is used to handle Frozen rows and columns.
 * @hidden
 */
var Freeze = /** @class */ (function () {
    function Freeze(parent, locator) {
        this.parent = parent;
        this.locator = locator;
        this.addEventListener();
    }
    Freeze.prototype.getModuleName = function () {
        return 'freeze';
    };
    Freeze.prototype.addEventListener = function () {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.on(events.initialLoad, this.instantiateRenderer, this);
        this.parent.on(events.initialEnd, this.wireEvents, this);
    };
    Freeze.prototype.wireEvents = function () {
        if (this.parent.frozenRows) {
            EventHandler.add(this.parent.getHeaderContent(), 'dblclick', this.dblClickHandler, this);
        }
    };
    Freeze.prototype.dblClickHandler = function (e) {
        if (parentsUntil(e.target, 'e-grid').id !== this.parent.element.id) {
            return;
        }
        this.parent.notify(events.dblclick, e);
    };
    Freeze.prototype.instantiateRenderer = function () {
        var renderer = this.locator.getService('rendererFactory');
        if (this.parent.getFrozenColumns()) {
            renderer.addRenderer(RenderType.Header, new FreezeRender(this.parent, this.locator));
            renderer.addRenderer(RenderType.Content, new FreezeContentRender(this.parent, this.locator));
        }
    };
    Freeze.prototype.removeEventListener = function () {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.off(events.initialLoad, this.instantiateRenderer);
    };
    Freeze.prototype.destroy = function () {
        this.removeEventListener();
    };
    return Freeze;
}());
export { Freeze };
